import React from "react";
import { RCL as useTranslation } from "../../components/RCL";
import * as style from "./NoibuLink.module.scss";
import { useNoibu } from "./useNoibu";

// TODO: Migrate to Component Library

export const transformNoibuLink = link => {
	const isHelpCode = link.url === "#noibu-help-code";
	return {
		...link,
		...(isHelpCode ? { isHelpCode } : {}),
	};
};

const NoibuLink = link => {
	const { getHelpCode, isLoading } = useNoibu();
	const translations = {
		linkTitleLoading: useTranslation({ searchKey: "noibu-link-loading" }),
		showHelpCode: useTranslation({ searchKey: "noibu-link-modal-title" }),
		unavailable: useTranslation({ searchKey: "noibu-link-unavailable" }),
	};

	const linkText = isLoading ? translations.linkTitleLoading : link.title;

	const handleOnClick = async e => {
		e.preventDefault();
		const code = await getHelpCode();
		if (code) {
			prompt(translations.showHelpCode, code);
		} else {
			alert(translations.unavailable);
		}
	};

	return (
		<button type="button" className={style.noibuLink} disabled={isLoading} onClick={handleOnClick}>
			{linkText}
		</button>
	);
};

export default NoibuLink;
